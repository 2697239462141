import $ from 'jquery'

function loadMore() {
  let canBeLoaded = true; 
  let bottomOffset = 2000; 

  $(window).scroll(function () {
    var data = {
      'action': 'loadmore',
      'query': php_vars.posts,
      'page': php_vars.current_page,
    };
    if ($(document).scrollTop() > ($(document).height() - bottomOffset) && canBeLoaded == true) {
      $.ajax({
        url: php_vars.ajax_url,
        data: data,
        type: 'POST',
        beforeSend: function (xhr) {
          canBeLoaded = false;
        },
        success: function (data) {
          if (data) {
            $('#posts-list').find('.grid__column:last-of-type').after(data); 
            canBeLoaded = true; 
            php_vars.current_page++;
          }
        }
      });
    }
  });
}

export default function initLoadMore(){
  if ( $('#posts-list').length ) {
    loadMore();
  }
}
