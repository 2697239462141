import Headroom from 'headroom.js'

export default function initHeadroom(){
  const theHeader = document.querySelector('.js-header')
  const body = document.querySelector('body')
  
  var options = {
    offset: 70,
    tolerance: 5,
    onUnpin : function(){
      setTimeout(function() {
        theHeader.classList.remove("headroom--unpinned");
        theHeader.classList.add("headroom--pinned");
      }, 2000);
    },
  }
  const headroom = new Headroom(theHeader, options)
  headroom.init()
}
